import moment from "moment-timezone";

import { phone as extractPhone } from "phone";
import {
  ICardProvider,
  ICustomer,
  IUser,
  TransactionType,
  UserType,
} from "@Types";
import { GiftCardDelivery } from "@Types/Igiftcard";
import isEmpty from "lodash/isEmpty";
import { IBankName } from "@Types/Ibank";
import { ILivitPayRecord } from "@Types/Ilivitpay";
import { IShop } from "@Types/Ishop";
import { displayDecimal } from "./feesCalculatorUtils";

type Day =
  | "today"
  | "yesterday"
  | "7days"
  | "14days"
  | "30days"
  | "90days"
  | "180days"
  | "365days";

export const formatDingPhoneNumber = (phone) => {
  if (isEmpty(phone)) {
    return null;
  }

  const { phoneNumber, isValid } = extractPhone(phone);

  if (isValid) {
    const formattedPhone = `+${phoneNumber.replace(/\D/g, "")}`;
    return formattedPhone;
  }

  return `+${phone?.replace(/\D/g, "")}`;
};

export const handleUserType = (user: IUser) => {
  let userType = UserType.USER;

  if (user?.isAgent) {
    userType = UserType.AGENT;
  } else if (user?.isPartner) {
    userType = UserType.PARTNER;
  }
  return userType;
};

export const formatPhoneNumber = (phone) => {
  if (isEmpty(phone)) {
    return null;
  }

  const data = extractPhone(phone);
  const { phoneNumber, isValid } = data;

  if (isValid) {
    return phoneNumber;
  }

  return phone;
};

export function formatEmailWithAsterisk(email: string) {
  if (isEmpty(email)) {
    return null;
  }

  const splitEmail = email.split("@");
  const domain = splitEmail[1];
  const name = splitEmail[0];
  return name.substring(0, 3).concat("******@").concat(domain);
}

export const filterByDate = (day: Day) => {
  let startOfDay = moment();
  const endOfDay = moment();
  switch (day) {
    case "yesterday":
      startOfDay = startOfDay.subtract(1, "days");
      break;

    case "7days":
      startOfDay = startOfDay.subtract(7, "days");
      break;

    case "14days":
      startOfDay = startOfDay.subtract(14, "days");
      break;

    case "30days":
      startOfDay = startOfDay.subtract(30, "days");
      break;

    case "90days":
      startOfDay = startOfDay.subtract(90, "days");
      break;

    case "180days":
      startOfDay = startOfDay.subtract(180, "days");
      break;

    case "365days":
      startOfDay = startOfDay.subtract(365, "days");
      break;

    default:
      break;
  }

  const payload = {
    startOfDay: startOfDay.startOf("day").format(),
    endOfDay: endOfDay.endOf("day").format(),
  };

  return payload;
};

export const removeDuplicateFromArray = (array, key) => {
  try {
    return array.reduce((arr, item) => {
      const removed = arr.filter(
        (i) => i?.resource[key] !== item?.resource[key]
      );
      return [...removed, item];
    }, []);
  } catch (error) {
    //  Error: ", error);
  }
};

export const convertEmailToPGecom = (email: string) => {
  const formattedEmail = `${email?.slice(0, email?.indexOf("@"))}@pgecom.org`;
  return formattedEmail;
};

export function extractPlatformName(url) {
  const domain = url
    ?.toLocaleLowerCase()
    ?.replace(" ", "")
    ?.replace("http://", "")
    ?.replace("http//", "")
    ?.replace("https://", "")
    ?.replace("HTTP://", "")
    ?.replace("HTTP:// ", "")
    ?.replace("www.", "")
    ?.split(/[/?#]/)[0];
  const parts = domain?.split(".");
  if (parts?.length === 2) {
    return parts[0];
  }
  if (parts?.length === 3 && parts[1] === "co") {
    return parts[0];
  }
  return url;
}

export const formatNumber = (num: number) => {
  if (!num) {
    return 0;
  }
  if (typeof num !== "number") {
    return displayDecimal(num);
  }

  return displayDecimal(num);
};

export function extractUpdatedBalance(str) {
  const regex = /now \$(\d+(?:\.\d{1,2})?) usd/;
  const match = str?.match(regex);
  if (match) {
    return match[0];
  }
  return null;
}

export function extractAmount(text) {
  if (!text) {
    return "--";
  }
  const regex = /\$\d+(?:\.\d+)?/g;
  const matches = text?.match(regex);
  if (matches) {
    return matches[matches.length - 1];
  }
  return "--";
}

export function extractEmailAddress(str) {
  if (!str) {
    return "No email";
  }
  const regex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  const match = str?.match(regex);
  if (match) {
    return match[0];
  }
  return null;
}

export function isTransactionDeposit(type: string) {
  let isDeposit = false;
  if (
    type === TransactionType.ZELLE_DEPOSIT ||
    type === TransactionType.OFFLINE_DEPOSIT ||
    type === TransactionType.SALES ||
    type === TransactionType.LENDING_DEPOSIT ||
    type === TransactionType.PAYPAL ||
    type === TransactionType.GOURDES_TO_USD ||
    type === TransactionType.BINANCE_DEPOSIT ||
    type === TransactionType.SOGEBANK_DEPOSIT ||
    type === TransactionType.FLUTTERWAVE_DEPOSIT ||
    type === TransactionType.BANK_RESERVAS_DEPOSIT ||
    type === TransactionType.BANK_BHD_LEON_DEPOSIT ||
    type === TransactionType.PG_PAY_DEPOSIT ||
    type === TransactionType.REFERRAL_DEPOSIT ||
    type === TransactionType.INVESTMENT_REPAYMENT ||
    type === TransactionType.SUDO_MASTERCARD_WITHDRAW ||
    type === TransactionType.SUDO_VISA_WITHDRAW ||
    type === TransactionType.REFUND ||
    type === TransactionType.BUH_DEPOSIT ||
    type === TransactionType.CREDIT_DEPOSIT ||
    type === TransactionType.CHASE_DEPOSIT ||
    type === TransactionType.WAVE_PAYMENT_DEPOSIT ||
    type === TransactionType.MONCASH_DEPOSIT_VIA_API ||
    type === TransactionType.CRYPTO_DEPOSIT ||
    type === TransactionType.CASHAPP_DEPOSIT ||
    type === TransactionType.UNIBANK_DEPOSIT ||
    type === TransactionType.PHYSICALCARD_COMMISSION ||
    type === TransactionType.PAYPAL_DEPOSIT ||
    type === TransactionType.REFERRAL_DEPOSIT ||
    type === TransactionType.TOPUP_DEPOSIT ||
    type === TransactionType.VISA_WITHDRAW ||
    type === TransactionType.PAYONEER_DEPOSIT ||
    type === TransactionType.WISE_DEPOSIT ||
    type === TransactionType.BANK_OF_AMERICA_DEPOSIT ||
    type === TransactionType.STRIPE_DEPOSIT ||
    type === TransactionType.MASTERCARD_DEPOSIT_CREDIT ||
    type === TransactionType.STRIPE ||
    type === TransactionType.TRANSFER_RECEIVED ||
    type === TransactionType.MONCASH_DEPOSIT ||
    type === TransactionType.VENMO_DEPOSIT ||
    type === TransactionType.TRANSFERPAM_RECEIVED ||
    type === TransactionType.BANK_DEPOSIT ||
    type === TransactionType.NATCASH_DEPOSIT ||
    type === TransactionType.CRYPTO_DEPOSIT ||
    type === TransactionType.CARD_BALANCE_RECHARGE ||
    type === TransactionType.MANUAL_BALANCE_RECHARGE_INCREASE ||
    type === TransactionType.REFUND_TRANSACTION ||
    type === TransactionType.REFUND
  ) {
    isDeposit = true;
  }

  return isDeposit;
}

export const handleTransactionLogo = (type: string) => {
  let transactionLogo = "/transaction/deposit.png";

  switch (type) {
    case TransactionType.ZELLE_DEPOSIT:
      transactionLogo = "/transaction/zelle.png";
      break;

    case TransactionType.PAYPAL:
      transactionLogo = "/transaction/paypal.png";
      break;

    case TransactionType.BANK_DEPOSIT:
      transactionLogo = "/transaction/bank_deposit.png";
      break;

    case TransactionType.MONCASH:
    case TransactionType.MONCASH_DEPOSIT:
      transactionLogo = "/transaction/moncash_deposit.png";
      break;

    case TransactionType.NATCASH_DEPOSIT:
      transactionLogo = "/transaction/natcash.png";
      break;

    case TransactionType.OFFLINE_DEPOSIT:
      transactionLogo = "/transaction/deposit.png";
      break;

    case TransactionType.REFUND_TRANSACTION:
      transactionLogo = "/transaction/refund.png";
      break;

    case TransactionType.CRYPTO:
    case TransactionType.CRYPTO_DEPOSIT:
      transactionLogo = "/transaction/crypto.png";
      break;

    case TransactionType.CARD_TRANSACTION:
      transactionLogo = "/transaction/purchase.png";
      break;

    case TransactionType.CASHAPP_DEPOSIT:
      transactionLogo = "/transaction/cashapp.png";
      break;

    case TransactionType.DISPUTE:
      transactionLogo = "/transaction/dispute.png";
      break;

    case TransactionType.TOPUP:
      transactionLogo = "/transaction/topup.png";
      break;

    case TransactionType.TRANSFER:
      transactionLogo = "/transaction/transfer.png";
      break;

    case TransactionType.VIRTUALCARD:
    case TransactionType.PHYSICALCARD:
    case TransactionType.GIFT_CARD:
      transactionLogo = "/transaction/virtual_card.png";
      break;

    case TransactionType.WITHDRAW:
      transactionLogo = "/transaction/withdraw.png";
      break;

    default:
      transactionLogo = "/transaction/deposit.png";
      break;
  }

  return transactionLogo;
};

export function getChangedKeys(obj1, obj2) {
  const changedKeys = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj1) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj1?.hasOwnProperty(key)) {
      if (obj1[key] !== obj2[key]) {
        changedKeys.push(key);
      }
    }
  }
  return changedKeys;
}

export function sortArrayOfObjectsByDate(arr, dateProp) {
  if (!arr?.length) {
    return [];
  }

  return arr?.slice().sort(function (a, b) {
    const dateA: any = new Date(a[dateProp]);
    const dateB: any = new Date(b[dateProp]);
    return dateB - dateA;
  });
}

export function findMatchingProducts(products, productIds) {
  return products.filter((product) => productIds.includes(product.id));
}

export const findShop = (shops: IShop[], shopId: string) => {
  const shop = shops.find(({ id }) => id === shopId);
  return shop;
};

export function extractUsernameFromEmail(email) {
  const index = email?.indexOf("@");
  if (index === -1) {
    // if the email doesn't contain "@", return the original email
    return email;
  }
  // otherwise, return the substring before the "@" symbol
  return email?.substring(0, index);
}

export const isTheWeekEnd = () => {
  const day = new Date().getDay();
  return day === 0 || day === 6;
};

export function formatMastercardCardNumber(cardNumber) {
  // Remove any spaces or dashes from the card number
  // eslint-disable-next-line no-useless-escape
  cardNumber = cardNumber.replace(/[\s\-]/g, "");

  // Mastercard numbers should have either 16 or 19 digits
  if (cardNumber.length !== 16 && cardNumber.length !== 19) {
    return "Invalid card number";
  }

  // Add spaces every 4 digits to format the number
  let formattedNumber = "";
  for (let i = 0; i < cardNumber.length; i += 1) {
    if (i % 4 === 0 && i > 0) {
      formattedNumber += " ";
    }
    formattedNumber += cardNumber[i];
  }

  return formattedNumber;
}

type IFormatFullName = {
  firstName?: string;
  lastName?: string;
  fullName?: string;
};

export function formatFullName(payload: IFormatFullName) {
  const firstName = payload?.firstName;
  const lastName = payload?.lastName;

  let fullName = payload?.fullName;

  if (firstName && lastName) {
    fullName = `${firstName} ${lastName}`;
  } else if (firstName) {
    fullName = firstName;
  } else if (lastName) {
    fullName = lastName;
  }

  const words = fullName?.toLowerCase().split(" ");
  const formattedWords = words?.map(
    (word) => word?.charAt(0).toUpperCase() + word?.slice(1)
  );

  return formattedWords?.join(" ") || "No name";
}

type IFormatCustomerFullName = {
  customerFirstName?: string;
  customerLastName?: string;
  fullName?: string;
};

export function formatCustomerFullName(payload: IFormatCustomerFullName) {
  const firstName = payload?.customerFirstName;
  const lastName = payload?.customerLastName;
  const fullName = payload?.fullName;

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (firstName) {
    return firstName;
  }
  if (lastName) {
    return lastName;
  }

  if (fullName) {
    return fullName;
  }
  return "No name";
}

export function formatReceiver(receiver: string) {
  if (!receiver || receiver?.includes("undefined")) {
    return "--";
  }

  const email = extractEmailAddress(receiver);

  if (email) {
    return email;
  }

  return receiver;
}

export function isValidEmail(email) {
  // regular expression pattern for email validation
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // test the email against the pattern and return the result
  return emailPattern.test(email);
}

export function stripLanguageRegionCode(languageCode) {
  if (isEmpty(languageCode)) {
    return "en";
  }

  if (languageCode?.includes("-")) {
    return languageCode?.split("-")[0];
  }

  return languageCode;
}

export function getFirstLanguage(langs: string) {
  if (isEmpty(langs)) {
    return "en";
  }

  let language = langs;

  if (langs?.includes(",")) {
    language = langs?.split(",")[0] || "en";
  }

  // remove the region code from the language
  const formattedLanguage = stripLanguageRegionCode(language);
  return formattedLanguage;
}

export const isTransactionIdReloadly = (transactionId) => {
  // Use a regular expression to match the input against the pattern of 5 digits
  const pattern = /^\d{5}$/;
  return pattern.test(transactionId);
};

export const getTotalAmountFromOrder = (arr: any[]) => {
  const totalAmount = arr.reduce(
    (accumulator, current) =>
      accumulator + Number(current?.amount) ||
      0 + (Number(current?.userFee) || 0),
    0
  );

  return displayDecimal(totalAmount);
};

// export const getTotalTransactionFromMasterCard = (arr: ILivitPayRecord[]) => {
//   const totalAmount = arr.reduce(
//     (accumulator, current) =>
//       accumulator + current?.transactionAmount?.amount / 100 || 0,
//     0
//   );

//   return totalAmount;
// };

export const getTotalTransactionFromMasterCard = (arr: ILivitPayRecord[]) => {
  const totalNegativeAmount = arr.reduce(
    (accumulator, current) =>
      accumulator +
      (current?.transactionAmount?.amount < 0
        ? current.transactionAmount.amount / 100
        : 0),
    0
  );

  return displayDecimal(totalNegativeAmount);
};

// Trim for any whitespaces
export function formatPhoneToMonCash(phone: string) {
  if (phone?.includes("+509")) {
    return `${phone?.trim()?.replace(/\s/g, "")}`;
  }

  return `+509${phone?.trim()?.replace(/\s/g, "")}`;
}

export function getItemByName(items: any[], name: string) {
  for (let i = 0; i < items.length; i += 1) {
    if (items[i].integrateName === name) {
      return items[i];
    }
  }
  return null;
}

export function isPast24Hours(date) {
  const now = moment();
  const past24Hours = moment().subtract(24, "hours");

  const result = moment(date).isBetween(past24Hours, now, null, "[]");
  return result;
}

export function getLastFiveCharacters(str) {
  if (typeof str !== "string" || !str) {
    return "--";
  }

  if (str?.length > 5) {
    return str.slice(-5);
  }

  return str;
}

export function expectedDeliveryDate(delivery: GiftCardDelivery) {
  const today = moment();
  let expectedDelivery = today;

  if (delivery === GiftCardDelivery.STANDARD) {
    expectedDelivery = today.add(2, "days");
  } else if (delivery === GiftCardDelivery.REGULAR) {
    expectedDelivery = today.add(1, "days");
  } else if (delivery === GiftCardDelivery.EXPRESS) {
    expectedDelivery = today.add(2, "hours");
  }

  return expectedDelivery.format("dddd, MMMM Do YYYY, HH:mm");
}

export function formatBankName(inputWord: string) {
  // Replace underscores and hyphens with spaces
  let formattedWord = inputWord?.replace(/[_-]/g, " ");

  // Capitalize the first letter
  formattedWord =
    formattedWord.charAt(0).toUpperCase() + formattedWord.slice(1);

  return formattedWord;
}

export function capitalizeWord(inputWord: string) {
  // Replace underscores and hyphens with spaces
  if (!inputWord) {
    return null;
  }
  let formattedWord = inputWord?.replace(/[_-]/g, " ");

  // Capitalize the first letter
  formattedWord =
    formattedWord.charAt(0).toUpperCase() + formattedWord.slice(1);

  return formattedWord;
}

export const createSlug = (name: string) => {
  const slug = name
    ?.toLowerCase()
    ?.replace(/\s/g, "-")
    ?.replace(/[^a-zA-Z0-9-]/g, "");

  return slug;
};

export const isBankHaiti = (bankName: IBankName | string) => {
  const isHaiti =
    bankName === IBankName.SOGEBANK ||
    bankName === IBankName.UNIBANK ||
    bankName === IBankName.BNC ||
    bankName === IBankName.CAPITAL_BANK ||
    bankName === IBankName.BUH;

  return isHaiti;
};

export const isBankTransactionTypeHaiti = (type: TransactionType | string) => {
  const isHaiti =
    type === TransactionType.SOGEBANK_DEPOSIT ||
    type === TransactionType.BUH_DEPOSIT ||
    type === TransactionType.UNIBANK_DEPOSIT ||
    type === TransactionType.CAPITAL_BANK;

  return isHaiti;
};

export const isBankDominicanRepublic = (
  transactionType: TransactionType | string
) => {
  const isDRC =
    transactionType === TransactionType.BANK_BHD_LEON_DEPOSIT ||
    transactionType === TransactionType.BANK_RESERVAS_DEPOSIT;

  return isDRC;
};

export function groupByDate(data) {
  const groupedData = {};

  data.forEach((item: ICustomer & { processedTimestamp?: string }) => {
    const date = moment(item.createdAt || item?.processedTimestamp).format(
      "YYYY-MM-DD"
    );

    if (!groupedData[date]) {
      groupedData[date] = [];
    }

    groupedData[date].push(item);
  });

  return groupedData;
}

export function formatCardProvider(cardType: ICardProvider) {
  let cardProvider = "Divvy Visa";

  const isLivitPayMasterCard = cardType === ICardProvider.LIVITPAY_MASTERCARD;
  const isSudoMasterCard = cardType === ICardProvider.SUDO_MASTERCARD;
  const isSudoVisa = cardType === ICardProvider.SUDO_VISA;

  if (isLivitPayMasterCard) {
    cardProvider = "LivitPay Mastercard";
  } else if (isSudoMasterCard) {
    cardProvider = "Sudo MasterCard";
  } else if (isSudoVisa) {
    cardProvider = "Sudo Visa";
  } else {
    cardProvider = "Divvy Visa";
  }

  return cardProvider;
}

export function extractUsernameFromSocialMedia(url: string) {
  // Define regular expression patterns for various social media platforms
  const socialMediaPatterns = [
    {
      platform: "Instagram",
      pattern: /https?:\/\/(?:www\.)?instagram\.com\/([A-Za-z0-9_]+)/,
    },
    {
      platform: "Twitter",
      pattern: /https?:\/\/(?:www\.)?twitter\.com\/([A-Za-z0-9_]+)/,
    },
    {
      platform: "YouTube",
      pattern: /https?:\/\/(?:www\.)?youtube\.com\/(?:channel\/|user\/)?([A-Za-z0-9_]+)/,
    },
    {
      platform: "Facebook",
      pattern: /https?:\/\/(?:www\.)?facebook\.com\/([A-Za-z0-9_]+)/,
    },
    {
      platform: "TikTok",
      pattern: /https?:\/\/(?:www\.)?tiktok\.com\/@([A-Za-z0-9_]+)/,
    },
    // Add more social media patterns as needed
  ];

  // Iterate through the patterns and try to match the URL
  // eslint-disable-next-line no-restricted-syntax
  for (const socialMedia of socialMediaPatterns) {
    const match = url.match(socialMedia.pattern);
    if (match && match[1]) {
      return { platform: socialMedia.platform, username: match[1] };
    }
  }

  // Return null if no match is found
  return null;
}

export function findState(states = [], query) {
  // Convert the query to uppercase for case-insensitive matching
  const uppercaseQuery = query?.trim()?.toLowerCase();

  if (isEmpty(states)) {
    return null;
  }

  // Search for the state by name or ISO code
  const foundState = states?.find(
    (state) =>
      state?.label?.toLowerCase() === uppercaseQuery ||
      state?.value?.toLowerCase() === uppercaseQuery
  );

  return foundState || null; // Return the found state or null if not found
}

type day =
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday";

export function isDay(dayName: day) {
  const today = new Date();
  const daysOfWeek = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const currentDay = daysOfWeek[today.getDay()].toLowerCase();

  return currentDay === dayName.toLowerCase();
}

// Generate the due time for the funds deposit
// If the order is placed on Thursday then the due time is Monday
// If the order is placed on Friday or Saturday then the due time is Tuesday
export const getDeliveryDueDate = (
  delivery: GiftCardDelivery,
  isDisplayHour?: boolean,
  userTimezoneName: string = "America/New_York"
) => {
  let currentTime = moment().add(2, "day");

  const isFriday = isDay("friday");
  const isSaturday = isDay("saturday");
  const isThursday = isDay("thursday");

  const isExpress = delivery === GiftCardDelivery.EXPRESS;
  const isUrgent = delivery === GiftCardDelivery.URGENT;
  const isRegular = delivery === GiftCardDelivery.REGULAR;
  const isInstant = delivery === GiftCardDelivery.INSTANT;

  if (isThursday || isFriday) {
    currentTime = moment().add(4, "day");
  } else if (isSaturday) {
    currentTime = moment().add(3, "day");
  }

  // If the delivery is express then the due time is 2 hours
  // If the delivery is urgent then the due time is 30 minutes
  // If the delivery is regular then the due time is 1 day
  if (isExpress) {
    currentTime = moment().add(2, "hour");
  } else if (isUrgent) {
    currentTime = moment().add(30, "minute");
  } else if (isRegular) {
    currentTime = moment().add(1, "day");
  } else if (isInstant) {
    currentTime = moment().add(2, "minute");
  }

  if (isTheWeekEnd() && (isExpress || isUrgent)) {
    currentTime = moment().add(1, "day");
  }

  const userTimezoneMoment = moment(currentTime).tz(userTimezoneName);

  if (isDisplayHour) {
    return userTimezoneMoment.format("ddd, MMM D, YYYY h:mm:ss A");
  }

  return userTimezoneMoment.utc().format("YYYY-MM-DD");
};

export const getMasterCardDeliveryDueDate = (
  delivery: GiftCardDelivery,
  isDisplayHour?: boolean,
  userTimezoneName: string = "America/New_York"
) => {
  const userTimezoneMoment = moment().tz(userTimezoneName);
  let currentTime = userTimezoneMoment.add(5, "day");

  const isFriday = isDay("friday");
  const isSaturday = isDay("saturday");
  const isThursday = isDay("thursday");

  const isExpress = delivery === GiftCardDelivery.EXPRESS;
  const isUrgent = delivery === GiftCardDelivery.URGENT;
  const isRegular = delivery === GiftCardDelivery.REGULAR;
  const isInstant = delivery === GiftCardDelivery.INSTANT;

  if (isThursday) {
    currentTime = userTimezoneMoment.add(7, "day");
  } else if (isFriday || isSaturday) {
    currentTime = userTimezoneMoment.add(6, "day");
  }
  // If the delivery is express then the due time is 2 days
  // If the delivery is urgent then the due time is 1 day
  // If the delivery is regular then the due time is 3 days
  if (isExpress) {
    currentTime = userTimezoneMoment.add(2, "day");
  } else if (isUrgent) {
    currentTime = userTimezoneMoment.add(1, "day");
  } else if (isRegular) {
    currentTime = userTimezoneMoment.add(3, "day");
  } else if (isInstant) {
    currentTime = userTimezoneMoment.add(2, "hour");
  }

  if (isDisplayHour) {
    return currentTime.format("ddd, MMM D, YYYY [at] h:mm:ss A");
  }

  return currentTime.utc().format("YYYY-MM-DD");
};

export function isIdInList(list = [], targetId) {
  return list?.some((item) => item.id === targetId);
}

export function generateCloudinaryPublicId(fileName) {
  // Extract the file name without extension
  const fileNameWithoutExtension = fileName?.replace(/\.[^/.]+$/, "");

  // Replace spaces with dashes
  const replacedSpaces = fileNameWithoutExtension?.replace(/\s+/g, "-");

  // Remove other special characters using a regular expression
  const cleanedFileName = replacedSpaces?.replace(/[^\w\d-_.]+/g, "");

  // Convert to lowercase
  const lowercaseFileName = cleanedFileName?.toLowerCase();

  // Add any additional transformations or checks here if needed

  return lowercaseFileName;
}

export function netliFyQueryParams(hash) {
  const response = hash
    .replace(/^#/, "")
    .split("&")
    .reduce((result, pair) => {
      const keyValue = pair.split("=");

      const data = result;
      const key = keyValue[0];
      const value = keyValue[1];
      data[key] = value;
      return data;
    }, {});

  return response;
}
export function getListOfReserverdDomains() {
  const reservedDomains = [
    "facebook",
    "instagram",
    "twitter",
    "youtube",
    "tiktok",
    "snapchat",
    "pinterest",
    "linkedin",
    "google",
    "whatsapp",
    "telegram",
    "signal",
    "wechat",
    "viber",
    "line",
    "kik",
    "discord",
    "tumblr",
    "reddit",
    "medium",
    "quora",
    "flickr",
    "twitch",
    "soundcloud",
    "spotify",
    "github",
    "bitbucket",
    "gitlab",
    "stackoverflow",
    "behance",
    "dribbble",
    "vimeo",
    "slideshare",
    "medium",
    "wordpress",
    "blogger",
    "wix",
    "weebly",
    "squarespace",
    "godaddy",
    "namecheap",
    "bluehost",
    "hostgator",
    "shopify",
    "bigcommerce",
    "walmart",
    "amazon",
    "ebay",
    "etsy",
    "alibaba",
    "aliexpress",
    "dhgate",
    "wish",
    "groupon",
  ];

  return reservedDomains;
}

export function has15MinutesPassed(previousDate) {
  // Get the current date and time using Moment.js
  const currentDate = moment();

  // Calculate the difference in minutes
  const minutesDifference = currentDate.diff(previousDate, "minutes");

  // Check if 15 minutes have passed
  return minutesDifference >= 15;
}

export function generateDomainVariations(baseDomain) {
  const domainExtensions = [
    ".com",
    ".org",
    ".app",
    ".net",
    ".io",
    ".co",
    ".xyz",
  ]; // Add more extensions as needed

  // Remove the "www" subdomain if present
  const baseWithoutSubdomain = baseDomain?.replace(/^www\./i, "");

  // Extract the base domain without the existing extension
  const baseWithoutExtension = baseWithoutSubdomain?.replace(/\.\w+$/, "");

  // Generate variations by appending different extensions
  const variations = domainExtensions?.map(
    (extension) => baseWithoutExtension + extension
  );

  return variations;
}

export function toCamelCase(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(toCamelCase);
  }

  return Object.keys(obj).reduce((acc, key) => {
    const camelKey = key.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });

    acc[camelKey] = toCamelCase(obj[key]);
    return acc;
  }, {});
}

export const imageUrlToObject = async (imageUrl, onSuccess) => {
  try {
    const response = await fetch(imageUrl);

    if (!response.ok) {
      throw new Error(
        `Failed to fetch image: ${response.status} ${response.statusText}`
      );
    }

    const blob = await response.blob();
    const file = new File([blob], "image.jpg", { type: blob.type });

    onSuccess(file);
    return file;
  } catch (error) {
    console.error("Error converting URL to File:", error);
  }
};

export function containsNumbersOrAlphanumeric(inputString) {
  // Regular expression to match any digit, alphanumeric character, or period
  const regex = /[0-9a-zA-Z.]/;

  // Regular expression to match if the input string contains any character that is not a digit, alphanumeric character, or period
  const negRegex = /[^0-9a-zA-Z.]/;

  // Test if the input string contains any digit, alphanumeric character, or period,
  // but not exclusively consisting of them
  return regex.test(inputString) && negRegex.test(inputString);
}

export const isFileSizeExceeded = (fileSize, limitInMegabytes?: number) => {
  const megabytes = limitInMegabytes || 10; // Default to 10MB
  const sizeLimit = megabytes * 1024 * 1024; // 10MB

  return fileSize > sizeLimit;
};

export function generateUsername(email) {
  // Extract the part of the email address before the '@' symbol
  const usernamePrefix = email.split("@")[0];

  // Remove any non-alphanumeric characters from the extracted prefix
  const sanitizedUsername = usernamePrefix.replace(/[^a-zA-Z0-9]/g, "");

  // Add a suffix or additional logic if needed

  return sanitizedUsername;
}

export function generateTemporaryPassword(length) {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let temporaryPassword = "";

  for (let i = 0; i < length; i += 1) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    temporaryPassword += charset[randomIndex];
  }

  return temporaryPassword;
}

export function createBankTransactionId({ createdAt, amount, newBankBalance }) {
  const extractNumbers = (value) =>
    parseFloat(value.replace(/[^0-9.]/g, "")).toFixed(2);

  const formattedDate = createdAt
    .split("/")
    .map((part) => part.padStart(2, "0"))
    .join("");
  const formattedAmount = extractNumbers(amount.toString());
  const formattedBankBalance = extractNumbers(newBankBalance.toString());

  const orderId = `${formattedDate}-${formattedBankBalance.replace(
    ".",
    ""
  )}-${formattedAmount.replace(".", "")}`;

  return orderId;
}

export function getLanguageLocale(language: string) {
  const whiteList = ["en", "fr", "es", "ht"];

  if (whiteList.includes(language)) {
    return language;
  }

  return "en";
}

export function getBrowserLanguage(req, locale) {
  if (!req?.headers) {
    return locale || "en";
  }

  const language = req?.headers["accept-language"];
  const languages = language?.split(",");
  const firstLanguage = languages[0]?.split(";")[0];

  return firstLanguage;
}

export function generalMonCashDepositUrl() {
  // const isBiller = globalsetting?.isMonCashBillerEnabled;

  // if (isBiller) {
  // return "/api/moncash/biller-token";
  // }

  return "/api/moncash/token";
}

export function buildUrlParams(obj) {
  return new URLSearchParams(obj).toString();
}

export function isReferenceCode(referenceCode) {
  return referenceCode?.startsWith("pg-");
}

export function stripHtml(html) {
  return html.replace(/<[^>]*>/g, "");
}

export function normalizeReferenceCode(referenceCode) {
  let matchedCode = referenceCode;

  // Convert 'Pg-' to 'pg-' and replace underscores with hyphens
  matchedCode = matchedCode
    ?.replace(/^Pg-/i, "pg-") // Convert 'Pg-' to 'pg-'
    ?.replace(/_/g, "-"); // Replace underscores with hyphens

  return matchedCode;
}

export function convertEditorJsToPlainText({ blocks }) {
  return blocks
    ?.map((block) => {
      switch (block.type) {
        case "paragraph":
          return block.data.text; // Extract paragraph text
        case "header":
          return block.data.text; // Extract header text
        case "list":
          return block.data.items.join(", "); // Join list items into a comma-separated string
        case "quote":
          return block.data.text; // Extract quote text
        // Handle other block types if needed
        default:
          return ""; // Skip unhandled block types
      }
    })
    .join(" ")
    .trim(); // Join the block texts with a space and trim excess spaces
}

export function normalizeFormidableFields(fields) {
  return Object.fromEntries(
    Object.entries(fields).map(([key, value]) =>
      Array.isArray(value) && value.length === 1
        ? [key, value[0]]
        : [key, value]
    )
  );
}
